<template>
    <div id="about">
        <PageTitle :title="title" />
        <div class="bio-container">
            <div class="bio">
                <!-- <div :data-aos="$store.getters.isMobile ? 'fade-up' : 'fade-right'" class="photo">
                    <img src="/images/shauna-walters.jpg" alt="Shauna Walters">
                </div> -->
                <div class="description">
                    <p data-aos="fade-up">
                        Introducing Shauna and Nicole of Walters | Plaxen Estates: a dynamic duo in the world of luxury real estate who have nurtured a remarkable 25-year bond while establishing themselves as industry leaders. At the heart of their mission lies a profound commitment to women's empowerment, a cause they passionately champion alongside esteemed partners Branden and Rayni Williams at The Beverly Hills Estates.
                    </p>
                    <p data-aos="fade-up">
                        Forged through shared experiences and an unbreakable bond, this instantly iconic female pair brings a unique perspective and a deep commitment to building, uplifting, and mentoring women in the industry and on their team—a niche that sets them apart. Aligning passion and purpose, Shauna and Nicole consistently achieve record-breaking sales while maintaining unwavering support for their clients and their commitment to advancing women in the real estate industry.
                    </p>
                    <p data-aos="fade-up">
                        Shauna Walters began representing clients in her hometown of Calabasas at age 21, quickly becoming the agent of choice due to her exceptional networking skills, magnetic personality, and an unusually mature grasp of the marketplace.
                    </p>
                    <p data-aos="fade-up">
                        Shauna's nearly 20 years of experience extends across the city and beyond, catering to clients seeking high-end properties in Southern California. During her 13-year tenure as a Luxury Sales Agent with the widely acclaimed Sally Forster Jones Group, she has honed her skills and built a reputation for persistent excellence. Notably, in 2021 she achieved a record-breaking $44 million sale of the Brentwood Oasis, turning the heads of many and solidifying her position as a leading force in the industry.
                    </p>
                    <p data-aos="fade-up">
                        Shauna's passions include her young son, Ryder, who is the light of her life. As the daughter of a professional athlete and a star softball player herself, she's committed to staying in shape and participating in activities like SoulCycle, boxing, and hot yoga. Additionally, Shauna is deeply involved in numerous dog charities, channeling her love for animals into making a positive impact both professionally and personally. With two rescue dogs, Rosie and River, and a vegan lifestyle, Shauna continues to be a dedicated advocate for the welfare of animals. Also licensed in Florida with a focus in Miami, Shauna sells properties on both West and East Coasts, bringing her expertise and love of luxury real estate to a diverse range of clients. She currently resides in the charming community of Toluca Lake and has lived all throughout Los Angeles, including Downtown, Malibu, and West Hollywood.
                    </p>
                    <p data-aos="fade-up">
                        Nicole Plaxen has enjoyed an equally stellar career. She honed her real estate expertise under the mentorship of one of the world's most renowned figures in luxury real estate, Sally Forster Jones. During her tenure as the team’s Executive Vice President of Sales from 2007 to 2023, Nicole played a pivotal role in driving the group’s sales to an impressive $10+ billion. She oversaw a team of 35 members and personally participated in over 4,000 transactions, including landmark sales such as the Spelling Manor for $85 million and Markus Persson's Trousdale estate for $70 million. Her client roster boasts professional athletes and high-net-worth individuals from around the globe.
                    </p>
                    <p data-aos="fade-up">
                        Nicole's deep affinity for real estate traces its roots back to her childhood, where she watched her mother skillfully navigate the world of buying and flipping homes throughout Los Angeles. This early exposure ignited a lifelong passion that would shape her remarkable journey.
                    </p>
                    <p data-aos="fade-up">
                        As a longstanding resident of Beverly Hills, Nicole's philanthropic commitments extend to various local and international causes. She embraces the opportunity to contribute positively to communities and causes close to her heart. In her personal life, Nicole finds fulfillment as a devoted mother to her two daughters, imparting to them the joys of life and the significance of giving back. She also harbors a deep love for dance, a passion for art, and a genuine enthusiasm for travel, fostering a rich appreciation for diverse cultures and experiences that enrich her perspective.
                    </p>
                </div>
            </div>
        </div>
        <div class="second-title">
            <h2 data-aos="fade-up">
                Integrity.  Passionate.  Tenacious.
            </h2>
        </div>
        <!-- <div class="details-container">
            <div class="details">
                <div class="left">
                    <p data-aos="fade-up">
                        Shauna's clients appreciate her ability to relate to them and her instinct for knowing what they're looking for in their next home. “They know I'll work hard to get them the best possible deal. I'm a good listener, a powerful negotiator, there when they need me, and aggressive without being overbearing.” Honesty, communication, and tenacity are the tools she keeps closest at hand in her effort to make them happy.
                    </p>
                </div>
                <div class="right">
                    <p data-aos="fade-up">
                        In addition to real estate, Shauna's other passions include her young son, Ryder, the light of her life - and staying in shape so she can keep up with him. The daughter of a professional athlete and a star softball player, she keeps fit with SoulCycle and hot yoga. A lifetime animal rescuer, she volunteers for rescue groups like St. Martin's Animal Foundation and The Vanderpump Dog Foundation.
                    </p>
                </div>
            </div>
        </div> -->
        <!-- <div class="zillow">
            <a href="https://www.zillow.com/profile/ShaunaSellsHomesLA" target="_blank" class="stars">
                <img data-aos="fade-up" src="/images/icon/star.svg" alt="Stars">
                <img data-aos="fade-up" data-aos-delay="100" src="/images/icon/star.svg" alt="Stars">
                <img data-aos="fade-up" data-aos-delay="200" src="/images/icon/star.svg" alt="Stars">
                <img data-aos="fade-up" data-aos-delay="300" src="/images/icon/star.svg" alt="Stars">
                <img data-aos="fade-up" data-aos-delay="400" src="/images/icon/star.svg" alt="Stars">
            </a>
            <h2 data-aos="fade-up">
                ZILLOW 5-STAR AGENT
            </h2>
        </div> -->
        <div class="big-photo">
            <img src="/images/tbhe.jpg" alt="team photo">
        </div>
        <div class="second-title">
            <h2 data-aos="fade-up">
                The Beverly Hills Estates
            </h2>
        </div>
        <div class="details-container">
            <div class="details">
                <p data-aos="fade-up">
                    The Beverly Hills Estates provides its client base of celebrities, fortune 500 executives and government officials with a unique, door-to-door white glove concierge service to suit its client's ever-changing demands. It is a hybrid of luxury concierge brokerage in a private members club for all things lifestyles, including art, design, construction, architecture, and wellness. The firm has reached the pinnacle of the global real estate market by specializing in architecturally significant and one-of-a-kind properties here in LA. Its motto ‘We don’t follow trends, we set them’ has not only led to record-breaking sales, but build their clients property portfolios.<br><br>
                    With over $14 Billion in company sales and recognized as L.A.'s highest-performing real estate team based on their impressive sales record of over $1.4 billion in sales for 2022, our founders, Branden and Rayni Williams is a husband-and-wife real estate duo specializing in architecturally significant and historic properties in Malibu, Trousdale, Beverly Hills, Los Feliz, and beyond. Their passion to preserve and protect Los Angeles' most iconic homes is mirrored in their representation of properties by great architects like Paul R. Williams and Frank Lloyd Wright. In 2019 they broke the record of the highest price Frank Lloyd Wright property ever sold by closing The Ennis house for $18M. They represent some of the most expensive listings in the world including the sale of  “The One” for $141M in Bel-Air.
                    <br><br>
                    The Beverly Hills Estates’ extraordinary service, innovative marketing strategies, expansive networks, and unsurpassed knowledge of the local area consistently set it apart. With access to the most exclusive properties in the world, The Beverly Hills Estates holds the key to all of your real estate transactions large or small.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "About",
    components: {
        PageTitle
    },
    data(){
        return{
            title: "About"
        }
    },
    metaInfo: {
        title: 'About',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description',
            content: "WALTERS | PLAXEN"
        }]
    },
}
</script>

<style lang="scss" scoped>
.bio-container{
    margin: 100px;
}
.bio{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    .photo{
        width: 50%;
        img{
            display: block;
            object-fit: cover;
            width: 100%;
        }
    }
    .description{
        width: 100%;
        max-width: 1000px;
    }
}
.second-title{
    margin: 100px 20px;
    text-align: center;
    text-transform: uppercase;
}

.big-photo{
    margin: 100px;
    overflow: hidden;
    img{
        width: 100%;
    }
}

.details-container{
     margin: 100px;
}
.details{
    max-width: 1000px;
    margin: 0 auto;
}

.zillow{
    margin: 100px;
    text-align: center;
    .stars img{
        margin-right: 10px;
    }
}

@media (max-width: $mobile) {
    .big-photo{
        margin: 40px 0;
        img{
            width: 100%;
        }
    }
    .bio-container{
        margin: 40px;
    }
    .bio{
        flex-direction: column;
        .photo{
            width: 100%;
        }
        .description{
            width: 100%;
        }
    }
    .second-title{
        margin: 40px;
        text-align: center;
    }

    .details-container{
        margin: 40px;
    }

    .zillow{
        margin: 40px 20px;
        text-align: center;
        .stars img{
            margin-right: 5px;
            width: 40px;
        }
    }
}
</style>